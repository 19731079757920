<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
           <p v-if="activeName == 'loan'" style="margin: 0px;">  借出：将公司资产借出给在职员工使用的场景</p>
           <p v-if="activeName == 'return'" style="margin: 0px;"> 归还：员工使用完成资产后，管理员将资产归还给公司的场景</p>
         </div>   
		
		 <el-tabs v-model="activeName"  @tab-click="handleClickTabs">
			 <div class="search-form">
			 	<el-form ref="form" :inline="true"  :model="search_key" prop="">
			 		<el-form-item label="">
			 			<el-input v-model="search_key.receipt_number" placeholder="输入关键字搜索"></el-input>
			 		</el-form-item>
			 		
			 	<el-button type="primary" @click="getData()">查询</el-button>
			 	<el-button type="primary" @click="handleExport()">导出全部</el-button>
			 	</el-form>
			  </div>
			  <div style="margin-bottom:10px;">
				<template v-if="!batchStatus">
					<el-button size="small" type="" @click="handleAdd()">添加</el-button>
				 </template>
				 <template v-else>
			 	<!-- <el-button size="small" type="" @click="handlePrint()">打印</el-button> -->
					<el-button size="small" type="danger" plain @click="handleDel(multipleSelection)">删除</el-button>
				</template>
			  </div>
		    <el-tab-pane label="借出" name="loan">
				<el-table
						:data="tableData" 
						 :row-key="getRowKeys"
						  @selection-change="handleSelectionChange"
						style="width: 100%;">
						<el-table-column
						type="selection"
						:reserve-selection="true"
						width="55">
						</el-table-column>
						<el-table-column  type="expand">
							<template  slot-scope="props">
								<el-table	
								style="width: 90%;margin: 0 auto;"
								:header-cell-style="{height:'35px',background: '#F2F6FC',padding: '0',fontSize:'12px'}"
								:row-style="{height:'40px'}"
								 :cell-style="{padding: '0'}"
								border
								:data="props.row.asset_list">
									<template v-for="item in assetTheadData">
										<el-table-column :width="item.width" :key="item.field_name" :label="item.label">
											<template slot-scope="scope">
												<span>{{scope.row[item.field_name]}}</span>
											</template>
										</el-table-column>
									</template>
								</el-table>
							</template>
						</el-table-column>
						<template v-for="item in theadData">
							<el-table-column :key="item.field_name" :label="item.label">
								<template slot-scope="scope">
									<div v-if="item.field_name == 'cate_id'" >
										<span>{{scope.row['cate_name']}}</span>
									</div>
									<div v-if="item.field_name == 'location_id'" >
										<span>{{scope.row['location_name']}}</span>
									</div>
									<div v-if="item.type == 4">
										<el-image style="width: 35px;height: 35px;" v-for="(imgItem,imgIndex) in scope.row[item.field_name]" :src="imgItem"></el-image>
									</div>
									<span v-if="
											item.type != 4 &&
											item.field_name !== 'location_id'
											&& item.field_name !== 'cate_id'">{{scope.row[item.field_name]}}</span>
									<!-- <span>{{scope.row[item.field_name]}}</span> -->
								</template>
							</el-table-column>
						</template>
						<el-table-column
							label="操作"
							>
							<template slot-scope="scope">
								<el-button  size="small" @click="handleApproval(scope.row.id)">审批信息</el-button>
								<el-button  size="small" type="danger" plain @click="handleDel(scope.row)">删除	</el-button>
							</template>
						</el-table-column>
				</el-table> 
			</el-tab-pane>
			<el-tab-pane label="归还" name="return">
				<el-table
					:data="tableData" 
					 :row-key="getRowKeys"
					  @selection-change="handleSelectionChange"
					style="width: 100%;">
					<template v-for="item in theadData">
						<el-table-column :key="item.field_name" :label="item.label">
							<template slot-scope="scope">
								<span>{{scope.row[item.field_name]}}</span>
							</template>
						</el-table-column>
					</template>
					<el-table-column
						label="操作"
						>
						<template slot-scope="scope">
							<el-button  size="small" @click="handleApproval(scope.row.id)">审批信息</el-button>
							<el-button  size="small" type="danger" plain @click="handleDel(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table> 
			</el-tab-pane>
			 <AddVue ref="childDialog" ></AddVue>
			 <Approvalvue ref="approval"></Approvalvue>
		 </el-tabs>
		
    </div>
</template>
<script>
import AddVue from '../Common/Add.vue'
import Approvalvue from '../Common/ApprovalDrawer.vue'
export default {
    components:{
        AddVue,
		Approvalvue
    },
    inject:['reload'],
   data() {
      return {
		  activeName:'loan',
		  search_key:{
			  name:'资产借出单',
			  receipt_number:'',
			  type:4
		  },
		  getRowKeys(row){
		      return row.id
		  },
		  tableData:[],
		  currentPage:1,
		  pagesize:10,
		  total:0,
		  assetTheadData:[],
		  theadData:[],
		   batchStatus:false,
      }
   },
   created(){
	   if(this.$route.query.activeName) this.activeName =  this.$route.query.activeName
	   if(this.$route.query.status) this.search_key.status = this.$route.query.status
       this.getData()
   },
   mounted(){
    
   },
   methods:{
       getData(){   //表格数据
            var arr = []
            this.$api.post('AssetLoan/index',this.search_key,res=>{
               if (res.code == 200) {
                   this.tableData =  res.data.list??[]
				   this.assetTheadData =  res.data.asset_thead
				   this.theadData =  res.data.thead
                   this.total =  res.data.count
               }
           },fail=>{
			   this.$message.error(fail.msg)
		   })
       },

		handleAdd(){
			this.$nextTick(()=>{
				this.$refs.childDialog.cardName = this.search_key.name
				this.$refs.childDialog.storageType  = this.search_key.type
				this.$refs.childDialog.addUrl  = 'AssetLoan/add'
				this.$refs.childDialog.editUrl =  'AssetLoan/edit'
				this.$refs.childDialog.handleAdd()
			})
			
		},
         //监听表格多选框
       handleSelectionChange(val){
           this.multipleSelection  = []
           var list = val;
            list.forEach(item=>{
                this.multipleSelection.push(item.id )
            })
			
			if(this.multipleSelection.length > 0){
				this.batchStatus =  true
			}else{
				this.batchStatus = false
			}
       },
	   
	   //选项卡切换
		handleClickTabs(tab,event){
			// console.log(this.theadData)
			if(this.activeName == 'loan'){	
				this.search_key.name =  '资产借出单'
				this.search_key.type = 4
			}else{
				this.search_key.name =  '资产归还单'
				this.search_key.type = 5 
			}
			this.getData()
		},
		
		handleExport(){
			var id = this.multipleSelection?this.multipleSelection.join(','):''
			location.href = '/api/admin/AssetStorage/export?id='+id
		},
		
		handleDel(rows){
			var id = [] 
			if(rows instanceof Array){
				id =  this.multipleSelection
			}else{
				id.push(rows.id)
			}
			this.$alert("确定删除该数据吗？", "删除提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
			            this.$api.delete("AssetLoan/del", { "id": id }, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		},


        getCurrPage(val){   //获取当前页
            this.currentPage =  val
            this.getData()
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
            this.getData()
        },
		handleApproval(id){	//审批信息
			this.$refs.approval.handleApproval(id,this.search_key.type,this.search_key.name)
		},
   }

}
</script>
<style lang="scss">

  .bg-purple {
      padding:10px;
    background:  #d9ecff;
  }
  .bg-purple-light {
    background: #d9ecff;
  }

</style>